@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
    .button {
        @apply border rounded-lg font-bold text-center py-2 px-4 inline-block;
    }

    .dark_box {
        background: linear-gradient(180deg, rgba(187, 176, 235, 0.1) 0%, rgba(135, 119, 204, 0) 100%), rgba(84, 67, 163, 0.8);
        backdrop-filter: blur(17.5px);
        color: #fff;
    }
    .title-base {
        @apply font-semibold font-inter
    }
    .title-3xl {
        @apply lg:text-3xl text-2xl font-bold font-inter
    }
    .title-2xl {
        @apply lg:text-2xl text-xl font-bold font-inter
    }
    .title-xl {
        @apply lg:text-xl text-lg font-bold font-inter
    }
    .title-lg {
        @apply text-lg font-bold font-inter
    }
    .title-md {
        @apply text-md font-bold font-inter
    }
    .title-sm {
        @apply text-sm font-bold font-inter
    }
    .title-primary {
        @apply font-semibold text-2xl text-primary
    }
    .title-primary-base {
        @apply font-bold text-primary font-inter
    }
    .title-primary-lg {
        overflow-wrap: break-word;
        hyphens: auto;
        @apply title-lg text-primary
    }
    .title-primary-md {
        @apply title-md text-primary
    }
    .title-primary-sm {
        @apply font-bold text-primary font-inter text-[13px]
    }
    .title-white-2xl {
        @apply font-bold font-inter lg:text-2xl text-xl text-white
    }
    .subtitle {
        @apply font-inter text-[#585371]
    }
    .start-box {
        background: linear-gradient(180deg, rgba(187, 176, 235, 0.1) 0%, rgba(135, 119, 204, 0) 100%), rgba(221, 219, 233, 0.8);
        backdrop-filter: blur(17.5px);
        @apply self-center lg:w-1/4 lg:h-[475px] lg:mr-7 lg:mt-0 mt-5 text-primary w-full lg:px-[42px] lg:py-[55px] px-[30px] py-[40px] dark:dark_box;
    }
    .start-box:first-child{
        background: #3A2699 url(./images/bg-box.svg) top right no-repeat;
        background-size: cover;
        color: #fff;
        margin-top: 0;
        @apply lg:px-[54px] dark:bg-white dark:bg-none dark:text-black
    }
    .start-box:first-child span{
        @apply text-secondary dark:text-[#575078]
    }
    .start-box:last-child {
        @apply lg:mr-[-5%] lg:pr-[5%]
    }
    .bg-dark {
        background: #3A2699 url(./images/bg-dark.svg) top right no-repeat;
    }
    .bg-dark-login {
        background: #3A2699 url(./images/bg-login.svg) bottom right no-repeat;
    }
    .survey-box {
        @apply lg:w-[calc(50%-17px)] w-full mb-[34px] even:lg:ml-[34px] rounded-[15px] lg:p-[35px] p-7 relative bg-[var(--category-color-light)];/*lg:h-[167px] */
    }
    
    .survey-box h3{
        @apply text-[var(--category-color)];
    }

    .survey-box .survey-image {
        @apply inline-block lg:w-[98px] lg:h-[98px] lg:mr-[25px] w-[68px] h-[68px] mr-[15px] bg-contain align-middle relative
    }
    .expand-button {
        @apply inline-block w-[36px] h-[36px] leading-[36px] absolute lg:right-[26px] lg:bottom-[26px] right-5 bottom-5 rounded-full after:content-[''] after:w-5 after:h-4 after:block after:m-auto after:bg-[url("./images/arrow.svg")] after:bg-center after:bg-no-repeat
    }
    .survey-box-sub-categories {
        transition: 0.3s all ease-in-out;
        @apply bg-white p-[46px] opacity-0 invisible absolute left-0 top-0 w-full z-[1] after:content-[''] after:w-[2px] after:bottom-[46px] after:left-0 after:top-[46px] after:rounded-sm after:absolute hidden after:opacity-90;
    }
    .survey-conf .survey-box-sub-categories {
        @apply bg-none pb-[140px] after:bottom-[140px] before:content-[''] before:absolute before:w-full before:top-0 before:left-0 before:bottom-[140px] before:bg-white before:z-[-1];
    }
    .survey-box.active .survey-box-sub-categories {
        @apply visible opacity-100 top-[100%] block
    }
    .survey-box-sub-categories:after {
        background: linear-gradient(180deg, rgba(58, 181, 140, 0) 0%, var(--category-color) 48.6%, rgba(58, 181, 140, 0) 100%);
    }
    .survey-box-sub-category .subcategory-image {
        @apply w-[60px] h-[60px] bg-center bg-no-repeat inline-block align-middle rounded-full lg:mr-5 mr-5 relative;
        /*background-image: url('./images/award.svg');*/
    }
    .survey-box-sub-category .question-box {
        @apply mt-4 bg-[#F8F8F8] rounded-xl text-[13px] text-[#696484] italic px-4 py-4
    }
    /*.survey-box-sub-category + .survey-box-sub-category{
       border-top: 1px solid #B3B3B3;
       margin-top: 35px;
       padding-top: 42px;
    }*/
    .step-banner-horizontal {
        @apply  h-[330px] mb-11 w-full bg-contain bg-center bg-no-repeat;
        background-image: url(./images/step-banner-horizontal.png);
    }
    .step-banner-vertical {
        @apply rounded-[20px] lg:h-4/5 lg:w-5/12 w-full lg:bg-contain bg-cover bg-center bg-no-repeat overflow-hidden h-[230px] lg:mb-0 mb-11 2xl:min-h-[75vh] lg:min-h-[90vh] md:h-[60vh];
    }
    .icon-secondary {
        @apply w-[63px] h-[63px] bg-secondary bg-center bg-no-repeat inline-block align-middle rounded-full mr-5;
    }
    .questions-wrapper {
        /*counter-reset: questions-counter 0;*/
        @apply p-0
    }
    .questions-wrapper li {
        /*counter-increment: questions-counter 1;  //before:content-[counter(questions-counter)] */
        @apply before:content-[attr(data-count)]  before:absolute before:bg-[#D1D1D1] before:w-8 before:h-8 before:leading-8 before:text-white before:rounded-full before:text-center before:text-sm before:font-bold before:font-inter before:left-0 before:top-0 relative lg:pl-[70px] lg:mb-10 my-10;
    }
    .questions-wrapper h3{
        @apply title-primary-md mb-6 lg:pl-0 pl-12;
    }
    .dot-select {
        @apply relative flex flex-wrap before:content-[''] before:left-8 before:right-8 before:absolute before:h-[1px] before:bg-[#D1D1D1] before:top-2
    }
    .dot-select .dot-select-option {
        @apply w-1/6 font-inter sm:text-sm text-[11px] text-center tracking-[.04em] text-[#585371] before:content-[''] before:w-[18px]  before:h-[18px] before:block before:mx-auto before:mb-3 before:rounded-full before:bg-[#D1D1D1] z-[1] cursor-pointer
    }
    .dot-select .dot-select-option.active:before{
        @apply bg-secondary 
    }
    
    .textarea {
        @apply bg-[#f4f4f4] block w-full rounded-[5px] h-[133px] p-5
    }
    .textarea:focus, .textarea:active, .textarea:hover {
        outline: none;
        box-shadow: none;
    }
    .icon-confetti {
        @apply w-[120px] h-[120px] bg-center bg-no-repeat inline-block;
        background-image: url('./images/confetti.svg');
    }
    .box-primary {
        @apply bg-primary text-white p-6 pl-9 rounded-[15px]
    }
    .icon-Growth, .icon-Orientation, .icon-Collaboration, .icon-Balance, .icon-Learning, .icon-Ownership {
        @apply w-11 h-11 inline-block align-middle rounded-full relative after:content-[''] after:absolute after:w-8 after:h-8 after:bg-center after:bg-no-repeat after:bg-contain after:left-[6px] after:top-[6px]
    }
    .icon-strength-weakness {
        @apply w-11 h-11 inline-block align-top rounded-full relative
    }
    .icon-strength-weakness img {
        @apply absolute w-8 h-8 left-[6px] top-[6px]
    }
    .icon-lg{
        @apply w-[70px] h-[70px] after:w-10 after:h-10 after:left-[15px] after:top-[15px] inline-block align-middle bg-no-repeat bg-center rounded-full relative after:content-[''] after:absolute after:bg-center after:bg-no-repeat after:bg-contain
    }
    .icon-border-rounded, .icon-Growth.icon-border-rounded, .icon-Orientation.icon-border-rounded, .icon-Collaboration.icon-border-rounded, .icon-Balance.icon-border-rounded, .icon-Learning.icon-border-rounded, .icon-Ownership.icon-border-rounded {
        background-color: transparent!important;
        border: 1px solid;
        @apply after:filter-none border
    }
    .text{
        @apply bg-[#F2F0F8] rounded-[7px] placeholder-[#8A8A8A] py-[10px] px-[15px] text-black focus:outline-none
    }
    .progress-bar {
        @apply bg-[#281A6A] w-full h-3 rounded relative;
    }
    .progress-bar > span{
        @apply bg-white h-3 rounded absolute top-0 left-0;
    }
    .progress-bar .self-assess-mark {
        @apply w-1 -top-[2px] -bottom-[2px] h-auto bg-secondary -ml-[2px];
    }
    .text-question-box {
        @apply mb-3
    }
    .text-question-box h3{
        @apply bg-[#F8F8F8] lg:px-8 pl-6 pr-11 py-6 rounded-[14px] mb-3 title-primary-md relative
    }
    .text-question-body {
        display: none;
    }
    .text-question-box.active .text-question-body {
        display: flex;
    }
    .box-secondary {
        @apply text-primary w-full lg:px-[93px] lg:py-10 px-[30px] py-[30px] rounded-xl bg-secondary;
    }
    .card-title{
        @apply font-inter font-bold lg:text-[30px] text-lg leading-[1.3]
    }
    .content-card {
        background: #F4F3F9 url(./images/bg-content-card.svg) top right no-repeat;
        @apply dark:text-white
    }
    .dark .content-card {
        background: #3A2699 url(./images/bg-content-card-dark.svg) top right no-repeat;
    }
    .card-image {
        @apply block w-full h-[278px] rounded-lg bg-center bg-cover
    }
    .nth-green:nth-child(4n-1), .nth-green:nth-child(4n){
        background-color: #97F5AC;
    }
    .summary-item {
        @apply self-center lg:py-3 lg:px-6 p-2 rounded-lg lg:even:bg-[#97F5AC] /*w-1/2 lg:odd:pr-10 lg:even:pl-10 lg:odd:rounded-l-lg lg:even:rounded-r-lg lg:nth-green*/
    }
    .box-white {
        @apply bg-white rounded-[15px] p-7 
    }
    .border-title {
        @apply text-[#281A6A] title-lg pb-6 mb-6 border-b-[1px] border-solid border-[#585371]
    }
    .survey-tabs {
        @apply relative z-0 mt-11 before:content-[''] before:absolute before:left-0 before:top-0 before:w-full before:h-full before:bg-primary before:rounded-[15px] leading-[1.2]
    }
    .survey-tab {
        @apply absolute -z-[2] left-0 bottom-[95%] w-[40%] even:left-[35%] bg-[#C0C0C0] text-primary font-inter pt-3 pb-5 rounded-t-[15px]
    }
    .survey-tab.active {
        @apply -z-[1] bg-primary text-[17px] pt-5 pb-6 text-white
    }
    .modal {
        @apply fixed z-20 top-0 left-0 w-full h-full
    }
    .modal-body {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        @apply fixed top-[50%] left-[50%] max-w-full lg:w-[920px] w-[90%] -translate-x-[50%] -translate-y-[50%] rounded-[15px] bg-white z-20;
    }
    .modal-overflow {
        @apply overflow-auto max-h-[85vh];
    }
    .modal-inner {
        @apply flex flex-wrap relative after:content-[''] after:w-[2px] after:bottom-[46px] lg:after:left-[50%] after:left-0 after:top-[280px] after:rounded-sm after:absolute after:opacity-90;
    }
    .modal-inner:after {
        background: linear-gradient(180deg, rgba(58, 181, 140, 0) 0%, var(--category-color) 48.6%, rgba(58, 181, 140, 0) 100%);
    }
    .modal .survey-box:nth-last-child(2), .modal .survey-box:last-child {margin-bottom: 0;}
    .modal .close-button {
        @apply absolute lg:-right-12 lg:-top-8 -top-8 -right-5 title-primary-md z-20
    }
    .modal .survey-box-sub-category {
        @apply lg:w-[calc(50%-17px)] w-full p-7 lg:px-[35px] lg:py-5 even:lg:ml-[34px] relative;
    }
    /*.modal .survey-box-sub-category + .survey-box-sub-category {
        @apply before:content-[''] before:top-0 before:left-7 before:right-7 before:h-[1px] before:bg-[#B3B3B3] before:absolute
    }*/
    .modal .overlay {
        @apply fixed z-10 top-0 left-0 w-full h-full bg-[#F2F2F2] bg-opacity-90
    }
    /*.modal .survey-box-sub-category:nth-child(3)::before{display: none;}*/
    .tooltip {
        @apply bg-black bg-opacity-70 absolute text-[11px] text-white py-1 px-2 min-w-[100px] rounded text-center bottom-[100%] left-[50%] -translate-x-[50%] mb-3 opacity-0 invisible transition-opacity;
    }
    .tooltip:after {
        border-width: 8px 4px 0 4px;
        border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
        @apply content-[''] absolute left-[50%] -translate-x-[50%] top-[100%]
    }
    .progress-bar .self-assess-mark:hover .tooltip{
        @apply visible opacity-100
    }
    section.no-results:before{
        backdrop-filter:blur(6px);
    }
    section.no-results {
        @apply relative before:content-[''] before:absolute before:left-0 before:top-0 before:right-0 before:bottom-0 before:bg-white before:bg-opacity-70 before:z-[1];
    }
    section.no-results > div:first-child, section.no-results > div:nth-child(2){
        @apply relative z-[1]
    }
    .card-content {
        @apply whitespace-normal text-md text-[#585371] dark:text-white font-inter
    }
    .card-content >*:first-child {
        @apply mb-11
    }
    .card-content h1 {
        @apply title-3xl text-primary dark:text-white
    }
    .card-content ol > li{
        @apply text-[#281A6A] dark:text-white title-lg relative pl-24 font-normal lg:mb-11 mb-7
    }
    .card-content ol > li::before {
        content:"Step " counter(list-item);
        @apply border-b-4 border-secondary absolute left-0 -top-[2px] font-bold pb-3
    }
    .card-content ul{
        list-style-type: "- ";
        @apply px-9 py-7 font-bold text-md bg-[#F4F3F9] dark:bg-primary text-[#585371] dark:text-white border-l-4 border-l-secondary lg:max-w-[65%] lg:my-12 my-7
    }
    .card-content ul li+li{
        @apply mt-4
    }
    .card-content p{
        @apply lg:mb-12 mb-7
    }
    .icon-inner-img {
        @apply w-full h-auto left-0 top-0 absolute
    }
    .icon-border-rounded .icon-inner-img {
        @apply w-3/4 left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]
    }
    .registration-modal {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        @apply absolute top-5 left-[50%] lg:p-10 p-6 lg:w-[60%] w-[90%] -translate-x-[50%] rounded-[15px] bg-white z-[1];
    }
}
#root {
    white-space: pre-line;
}
.icon-leadership {
    background-image: url('./images/leadership.svg');
}
.icon-feedback {
    background-image: url('./images/feedback.svg');
}
.icon-data {
    background-image: url('./images/data.svg');
}
.icon-results {
    background-image: url('./images/results.svg');
}
.icon-clock {
    background-image: url('./images/clock.svg');
}

.icon-strength-weakness img, .icon-Growth:after, .icon-Orientation:after, .icon-Collaboration:after, .icon-Balance:after, .icon-Learning:after, .icon-Ownership:after {
    filter: brightness(0) invert(1);
}
.bg-Growth-light{
    background-color: #e4f5ef
}
.element-Growth, .modal-Growth {
    --category-color: #32B78B;
    --category-color-light: #e4f5ef;
}
.text-Growth {
    color: var(--category-color);
}
.element-Growth .expand-button, .element-Growth .survey-box-sub-category .subcategory-image, .icon-Growth, .bg-Growth {
    background-color: #32B78B!important;
}
/*.element-Growth .survey-image, .icon-Growth:after {
    background-image: url(./images/growth.svg);
}*/
.icon-Growth {
    color: #32B78B;
}

.element-Orientation, .modal-Orientation{
    --category-color: #479CDA;
    --category-color-light: #E8F3FB;
}
.bg-Orientation-light{
    background-color: #E8F3FB
}
.text-Orientation {
    color: #479CDA
}
.element-Orientation .expand-button, .element-Orientation .survey-box-sub-categories .subcategory-image, .icon-Orientation, .bg-Orientation {
    background-color: #479CDA!important
}
/*.element-Orientation .survey-image, .icon-Orientation:after {
    background-image: url(./images/orientation.svg);
}*/
.icon-Orientation{
    color: #479CDA;
}

.element-Collaboration, .modal-Collaboration{
    --category-color: #CE992F;
    --category-color-light: #fcf5e7;
}
.bg-Collaboration-light{
    background-color: #fcf5e7
}
.text-Collaboration {
    color: #CE992F
}
.element-Collaboration .expand-button, .element-Collaboration .survey-box-sub-categories .subcategory-image, .icon-Collaboration, .bg-Collaboration {
    background-color: #CE992F!important
}
/*.element-Collaboration .survey-image, .icon-Collaboration:after {
    background-image: url(./images/collaboration.svg);
}*/
.icon-Collaboration{
    color: #CE992F;
}

.element-Balance, .modal-Balance{
    --category-color: #3A2699;
    --category-color-light: #EAE9F4;
}
.bg-Balance-light{
    background-color: #EAE9F4
}
.text-Balance {
    color: #3A2699
}
.element-Balance .expand-button, .element-Balance .survey-box-sub-categories .subcategory-image, .icon-Balance, .bg-Balance {
    background-color: #3A2699!important
}
/*.element-Balance .survey-image, .icon-Balance:after {
    background-image: url(./images/balance.svg);
}*/
.icon-Balance{
    color: #3A2699;
}

.element-Learning, .modal-Learning{
    --category-color: #7A4CC4;
    --category-color-light: #F1E9F8;
}
.bg-Learning-light{
    background-color: #F1E9F8
}
.text-Learning {
    color: #7A4CC4
}
.element-Learning .expand-button, .element-Learning .survey-box-sub-categories .subcategory-image, .icon-Learning, .bg-Learning {
    background-color: #7A4CC4!important
}
/*.element-Learning .survey-image, .icon-Learning:after {
    background-image: url(./images/learning.svg);
}*/
.icon-Learning{
    color: #7A4CC4;
}
.element-Ownership, .modal-Ownership {
    --category-color: #E2376B;
    --category-color-light: #FCEBF1;
}
.bg-Ownership-light{
    background-color: #FCEBF1
}
.text-Ownership {
    color: #E2376B
}
.element-Ownership .expand-button, .element-Ownership .survey-box-sub-categories .subcategory-image, .icon-Ownership, .bg-Ownership {
    background-color: #E2376B!important
}
/*.element-Ownership .survey-image, .icon-Ownership:after {
    background-image: url(./images/ownership.svg);
}*/
.icon-Ownership{
    color: #E2376B;
}
.bg-small-dark {
    background-image: url(./images/bg-small-dark.svg);
    background-position: top right;
    background-repeat: no-repeat;
}
.bg-small-dark-2 {
    background-image: url(./images/bg-small-dark-2.svg);
    background-position: top right;
    background-repeat: no-repeat;
}
@media (min-width: 1024px) AND (max-height: 680px){
    .start-box h3 {margin-bottom: 30px;}
    .start-box a.button {margin-top: 50px;}
    .start-box {
        height: 80%;
        padding-top: 45px;
        padding-bottom: 45px;
    }
}
